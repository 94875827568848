.preloader-layout {
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader-layout::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.945);
}

.preloader {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 2em;
    z-index: 60;
}

.preloader span {
    width: 0.3em;
    height: 1em;
    background-color: #DC2626;
}

.preloader span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
}

.preloader span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
}

.preloader span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
}

.preloader span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
    0%,
    100% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(2);
    }
}